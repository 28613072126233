import { post, get } from './httpClient'
export const getsAllUsers = (params) => get('/user/getuser', { ...params })
export const updateRoles = (object) => post('/user/updaterole', object)
export const searchUsers = (object) => get('/user/getuser', object)
export const filterRoleUsers = (params) =>
  get('/user/getuserbyrole', { ...params })
export const changePassword = (object) => post('/auth/changepassword', object)
export const updateUser = (body) => post('/user/updateuser', body)
export const addRole = (body) => post('/user/addrole', body)
export const deleteRole = (body) => post('/user/removerole', body)
export const getRoles = () => get('/user/allrole')
