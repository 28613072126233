import React, { useState } from 'react'
import styles from './password.module.scss'

import { Link, useHistory } from 'react-router-dom'

//components antd
import { Form, Input, Button, notification } from 'antd'

//icons antd
import { UserOutlined } from '@ant-design/icons'

import { useDispatch } from 'react-redux'
import logo from 'assets/img/logo.png'
import { ROUTES, ACTION } from 'consts'

//apis
import { getRecoveryCode } from 'apis/auth'

const ForgotPassword = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const onFinish = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const { username } = form.getFieldsValue()
      const res = await getRecoveryCode(username)

      //request otp nếu thành công sẽ qua xác nhận otp
      if (res.status === 200)
        history.push({
          pathname: ROUTES.NEW_PASSWORD,
          state: { username },
        })
      else
        notification.error({
          message: 'Error',
          description: 'Send email failed!',
        })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {

      notification.error({
        message: 'Error',
        description: 'Send email failed!',
      })
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  return (
    <div className={styles['login']}>
      <div className={styles['login_img_parent']}>
        <img className={styles['login_img']} src={logo} alt="" />
        {/* <div>Vie</div> */}
      </div>
      <div className={styles['login_title']}>
        <div className={styles['login_title_top']}>Forgot Password</div>
        <div className={styles['login_title_bottom']}>
          Enter the username associated with your account to reset your password
        </div>
      </div>
      <Form className={styles['login_bottom']} form={form} onFinish={onFinish}>
        <Form.Item
          className={styles['login_bottom_email']}
          name="username"
          rules={[{ required: true, message: 'Please input username!' }]}
        >
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <div className={styles['login_bottom_left_button_parent']}>
          <Form.Item>
            <Button
              className={styles['login_bottom_left_button']}
              type="primary"
              htmlType="submit"
            >
              Send
            </Button>
          </Form.Item>
        </div>
        <Link to={ROUTES.LOGIN}>
          <div className={styles['back_to_login']}>Back to login?</div>
        </Link>
      </Form>

      <div className={styles['copyright']}>
        <div>Copyright © 2021 Vie. All rights reserved.</div>
      </div>
    </div>
  )
}

export default ForgotPassword
