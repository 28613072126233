import React, { useState, useEffect } from 'react'
import styles from './mail.module.scss'

import { ACTION, ROUTES } from 'consts'
import logo from 'assets/img/logo.png'

import { useDispatch } from 'react-redux'

import { Link, useHistory, useLocation } from 'react-router-dom'

//apis
import { acceptOTP, getRecoveryCode, login } from 'apis/auth'

//antds
import { Row, Col, Form, Input, Button, notification } from 'antd'

export default function Mail(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [form] = Form.useForm()

  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')

  const _acceptOTP = async (recoverycode) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      const res = await acceptOTP({ username, recoverycode })
      console.log(res)
      if (res.status === 200) {
        //xac thuc thanh cong thi dang nhap
        const resLogin = await login({
          username,
          password: location.state && location.state.passwordLogin,
        })

        if (resLogin.status === 200) {
          dispatch({ type: ACTION.LOGIN, data: resLogin.data })
          history.push(ROUTES.DASHBOARD)
          notification.success({
            message: 'Success',
            description: 'Authentication OTP success',
          })
        } else history.push(ROUTES.LOGIN)

      } else
        notification.error({
          message: 'Error',
          description: 'Authentication OTP failed!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const resendOTP = async (username) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getRecoveryCode(username)
      console.log(res)
      if (res.status === 200)
        notification.success({
          message: 'Success',
          description: 'Please check your email again!',
        })
      else
        notification.error({
          message: 'Error',
          description: 'Resend otp failed!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const onFinish = () => {
    try {
      const numberOTP = form.getFieldValue()
      const otp =
        numberOTP.number1;
      _acceptOTP(otp)
    } catch (error) {

    }
  }
  console.log(location.state)
  useEffect(() => {
    //Nếu ko có thông tin user để xác thực thì cho back về
    if (!location.state) history.goBack()
    else {
      if (location.state.mail) setEmail(location.state.mail)
      if (location.state.username) setUsername(location.state.username)
    }
  }, [])

  return (
    <div className={styles['login']}>
      <div className={styles['login_img_parent']}>
        <img className={styles['login_img']} src={logo} alt="" />
        {/* <div>Vie</div> */}
      </div>
      <div className={styles['login_title']}>
        <div className={styles['login_title_top']}>OTP Code Verification</div>
        <div className={styles['login_title_bottom']}>
          Verification code has been sent via
        </div>
        <div className={styles['login_title_bottom_email']}>
          {`Email: ${email}`}
        </div>
        <div className={styles['login_title_bottom']}>Please enter the OTP</div>
      </div>

      <Form className={styles['login_bottom']} form={form} onFinish={onFinish}>
        <Row
          style={{
            backgroundColor: 'white',
            height: '3rem',
            padding: '0.5rem 1rem 0 1rem',
          }}
          className={styles['login_bottom_email']}
        >
          <Col
            className={styles['login_bottom_email_col']}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            {' '}
            <Form.Item name="number1">
              <Input
                bordered={false}
                style={{
                  textAlign: 'center',
                  width: '100%',
                  borderBottom: '2.5px solid grey',
                  paddingTop: '1rem',
                }}
                maxLength={5}
                size="large"
              // prefix={<UserOutlined className="site-form-item-icon" />}
              // placeholder="Your email"
              />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles['login_bottom_left_button_parent_re_send']}>
          <div className={styles['login_bottom_left_button_parent_left']}>
            You have not received the code?
          </div>
          <div
            className={styles['login_bottom_left_button_parent_right']}
            onClick={() => resendOTP(username)}
          >
            Resend OTP
          </div>
        </div>
        <div className={styles['login_bottom_left_button_parent']}>
          <Form.Item>
            <Button
              className={styles['login_bottom_left_button']}
              type="primary"
              htmlType="submit"
            >
              Account creation confirmation
            </Button>
          </Form.Item>
        </div>
        <Link to={ROUTES.LOGIN}>
          <div className={styles['back_to_login']}>Back to login?</div>
        </Link>
      </Form>

      <div className={styles['copyright']}>
        <div>Copyright © 2021 Vie. All rights reserved.</div>
      </div>
    </div>
  )
}
