import { post } from './httpClient'

export const login = (body) => post('/auth/login', body)
export const register = (body) => post('/auth/register', body)

//xác thưc otp để active tài khoản
export const acceptOTP = (body) => post('/auth/active', body)

//gửi lại otp
export const getRecoveryCode = (username) =>
  post('/auth/getrecoverycode', { username })

//reset password
export const recoverPassword = (body) => post('/auth/recoverypass', body)
