import { ACTION, ROUTES } from 'consts';
import { decodeJWT } from 'utils';

// nhận data từ server
const initialState = {
  dataUser: {},
  loading: false,
};

var login = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOGIN: {
      var data = decodeJWT(action.data.accessToken);
      console.log(data);
      if (data) {
        localStorage.setItem('accessToken', action.data.accessToken);
        localStorage.setItem('refreshToken', action.data.refreshToken);
        if (action.data.user)
          var { username, role, balance } = action.data.user;

        if (username) localStorage.setItem('username', username);

        var { balance, username, role } = data;

        if (balance) localStorage.setItem('balance', JSON.stringify(balance));
        if (username)
          localStorage.setItem('username', JSON.stringify(username));
        if (role) localStorage.setItem('role', JSON.stringify(role));
      }

      return {
        ...state,
        dataUser: decodeJWT(action.data.accessToken),
      };
    }

    case ACTION.LOGOUT: {
      localStorage.clear();
      return {
        ...state,
        dataUser: {},
      };
    }

    case ACTION.LOADING: {
      state.loading = action.data;
      return state;
    }

    default:
      return state;
  }
};
export default login;
