import React, { useState, useEffect } from 'react'
import styles from './mail.module.scss'

import { ACTION, ROUTES } from 'consts'
import logo from 'assets/img/logo.png'

import { useDispatch } from 'react-redux'

import { Link, useHistory, useLocation } from 'react-router-dom'

//apis
import { getRecoveryCode, recoverPassword } from 'apis/auth'

//antds
import { Row, Col, Form, Input, Button, notification } from 'antd'

export default function NewPassword(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [form] = Form.useForm()

  const [username, setUsername] = useState('')
  const [inputOtp, setInputOtp] = useState('')
  const [newPass, setNewPass] = useState('')

  const resendOTP = async (username) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      const res = await getRecoveryCode(username)

      if (res.status === 200)
        notification.success({
          message: 'Success',
          description: 'Please check your email again!',
        })
      else
        notification.error({
          message: 'Error',
          description: 'Resend otp failed!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      notification.error({
        message: 'Error',
        description: 'Resend otp failed!',
      })

    }
  }

  const resetPassword = async (body) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await recoverPassword({ ...body })

      if (res.status === 200) {
        history.push(ROUTES.LOGIN)
        notification.success({ message: 'Reset password success' })
      } else notification.error({ message: 'Reset password failed' })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {

      notification.error({
        message: 'Error',
        description: 'Reset password failed!',
      })
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const onFinish = async () => {
    try {
      const data = form.getFieldValue()
      const otp = data.otp;
      const body = {
        recoverycode: otp,
        username,
        newpassword: data.newpassword,
      }

      resetPassword(body)
    } catch (error) {

    }
  }

  useEffect(() => {
    // Nếu ko có email để lấy password new thì cho back về
    if (!location.state) history.goBack()
    else {
      if (location.state.username) setUsername(location.state.username)
    }
  }, [])

  return (
    <div className={styles['login']}>
      <div className={styles['login_img_parent']}>
        <img className={styles['login_img']} src={logo} alt="" />
      </div>
      <div className={styles['login_title']}>
        <div className={styles['login_title_top']}>OTP Code Verification</div>
        <div className={styles['login_title_bottom']}>
          Verification code has been sent via
        </div>
        <div className={styles['login_title_bottom_email']}>
          {`Username: ${username}`}
        </div>
        <div className={styles['login_title_bottom']}>Please enter the OTP</div>
      </div>

      <Form
        layout="vertical"
        className={styles['login_bottom']}
        form={form}
        onFinish={onFinish}
      >
        <Row
          style={{
            backgroundColor: 'white',
            height: '3rem',
            padding: '0.5rem 1rem 0 1rem',
          }}
          className={styles['login_bottom_email']}
        >
          <Col
            className={styles['login_bottom_email_col']}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            {' '}
            <Form.Item name="otp">
              <Input
                bordered={false}
                style={{
                  textAlign: 'center',
                  width: '100%',
                  borderBottom: '1px solid grey',
                  paddingTop: '1rem',
                }}
                maxLength={5}
                size="large"
                onChange={(e) => setInputOtp(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles['login_bottom_left_button_parent_re_send']}>
          <div className={styles['login_bottom_left_button_parent_left']}>
            You have not received the code?
          </div>
          <div
            className={styles['login_bottom_left_button_parent_right']}
            onClick={() => resendOTP(username)}
          >
            Resend OTP
          </div>
        </div>

        <Form.Item
          required
          label={
            <p style={{ color: 'white', fontWeight: 500, marginBottom: 0 }}>
              New password
            </p>
          }
          name="newpassword"
          rules={[
            {
              required: true,
              message: 'Please input new password',
            },
          ]}
          style={{ width: '22.5rem', marginBottom: '20px' }}
        >
          <Input.Password
            placeholder="New password"
            size="large"
            style={{ width: '100%' }}
            onChange={(e) => setNewPass(e.target.value)}
          />
        </Form.Item>
        <div className={styles['login_bottom_left_button_parent']}>
          <Form.Item>
            <Button
              className={styles['login_bottom_left_button']}
              type="primary"
              htmlType="submit"
              disabled={inputOtp && newPass ? false : true}
            >
              Reset Password
            </Button>
          </Form.Item>
        </div>
        <Link to={ROUTES.LOGIN}>
          <div className={styles['back_to_login']}>Back to login?</div>
        </Link>
      </Form>
    </div>
  )
}
